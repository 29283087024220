import React from 'react'
import Layout from 'gatsby-theme-psg/src/components/layout/Layout'
import Header from '../components/Header'
import Lead from '../components/Lead';

const NotFoundPage = () => (
  <Layout>
      <Header background='rgba(255,120,0,0.85)'>
        <Lead title="Niet gevonden" content="<p>Deze pagina bestaat niet...</p>"/>
      </Header>
  </Layout>
)

export default NotFoundPage
